import lodash from 'lodash';
import Fetch from '@/utils/fetch';
import { API_URLS } from '@/constants/url';
import Joi, { AnySchema } from 'joi';

const ruleInteger = /^-?\d+$/;
const ruleUnsignedInteger = /^\d+$/;
const ruleRequired = /^.+$/;
const ruleNullable = /^.*$/;
const rulePropsSingle = /^\d+@\d+$/;
const rulePropsSingleAndNullable = /^(\d+@\d+)?$/;
export const rulePropsMany = /^\d+@\d+(\|\d+@\d+)*$/;
const ruleArrayWithLine = /^\d+(\|\d+)*$/;

const rulePropLimit = Joi.object({
  propId: Joi.number().integer().required(),
  propNum: Joi.number().integer().required(),
});

const categoryNameProps = 'props';
const categoryNameMailTemplate = 'mailTemplate';

export interface IField {
  title: string; //网页显示的名字
  name: string; //后端存储的字段名
  alias?: string; //由于历史原因，前后端的名字不一致，为了保证兼容，后端上传做映射，如果alias存在就使用alias，否则就是name
  rule?: RegExp | AnySchema; //正则校验或函数校验
}

export interface ISystemConfigItem {
  title: string; //网页显示的名字
  name: string; //后端存储的名字
  alias?: string; //由于历史原因，前后端的名字不一致，为了保证兼容，后端上传做映射，如果alias存在就使用alias，否则就是name
  fieldSet: IField[];
}

export const systemConfig: ISystemConfigItem[] = [
  {
    title: '道具配置',
    name: categoryNameProps,
    alias: 'baseItem',
    fieldSet: [
      {
        title: '类型',
        name: 'type',
        alias: 'itemType',
        rule: ruleUnsignedInteger,
      },
      { title: '购买价格', name: 'price' },
      { title: '堆叠数量', name: 'maxNum', rule: ruleUnsignedInteger },
      { title: '单次上限', name: 'singleLimit', rule: ruleUnsignedInteger },
      { title: '步增值', name: 'stockIncrement', rule: ruleUnsignedInteger },
      { title: '预警值', name: 'stockPreWarning', rule: ruleUnsignedInteger },
      {
        title: '是否自动补充库存',
        name: 'autoReplenishStock',
        rule: ruleUnsignedInteger,
      },
      { title: '随机礼包配置', name: 'randomAttr', rule: ruleNullable },
    ],
  },
  {
    title: '道具包配置',
    name: 'propsPack',
    alias: 'props_pack',
    fieldSet: [
      { title: '类型', name: 'type', rule: ruleUnsignedInteger },
      { title: '购买价格', name: 'price', rule: ruleRequired },
      { title: '包内道具', name: 'props', rule: ruleRequired },
    ],
  },
  {
    title: '充值类商品配置',
    name: 'shopRecharge',
    alias: 'shop_recharge',
    fieldSet: [
      { title: '类型', name: 'type', rule: ruleUnsignedInteger },
      {
        title: 'ios商品id 不能重复使用',
        name: 'iosProductId',
        alias: 'ios_product_id',
        rule: ruleRequired,
      },
      {
        title: '安卓商品id 不能重复使用',
        name: 'androidProductId',
        alias: 'android_product_id',
        rule: ruleRequired,
      },
      {
        title: '价格(BRL)-分',
        name: 'priceBRL',
        alias: 'luxpag_money',
        rule: ruleUnsignedInteger,
      },
      { title: '获得物品/商品', name: 'props', rule: ruleRequired },
      {
        title: '首充的额外奖励',
        name: 'firstTimeReward',
        alias: 'first_time_reward',
      },
      { title: 'vip点数', name: 'vipPoint', rule: ruleUnsignedInteger },
      {
        title: 'luxpag_subject,订单页面显示用',
        name: 'luxpagSubject',
        alias: 'luxpag_subject',
        rule: ruleRequired,
      },
      {
        title: 'luxpag_content,订单页面显示用',
        name: 'luxpagContent',
        alias: 'luxpag_content',
        rule: ruleRequired,
      },
    ],
  },
  {
    title: '运营活动-转盘奖池',
    name: 'activityTurntable',
    alias: 'packSpinWheel',
    fieldSet: [
      { title: '消耗', name: 'cost', rule: rulePropsSingle },
      { title: '抵用券', name: 'costCoupon', rule: rulePropsSingleAndNullable },
      {
        title: '奖池，每次奖盘的数据是从奖池中按照权重抽出来的',
        name: 'poor',
        rule: /^\d+@\d+,\d+,\d+(;\d+@\d+,\d+,\d+)*$/,
      },
    ],
  },
  {
    title: '运营活动-通行证',
    name: 'activityPassport',
    alias: 'passport',
    fieldSet: [
      {
        title: '免费通行证奖励',
        name: 'freeRewards',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '高级通行证奖励',
        name: 'rechargeRewards',
        rule: rulePropsSingleAndNullable,
      },
    ],
  },
  {
    title: '运营活动-广告水果机',
    name: 'activityDailyAD',
    alias: 'dailyAD',
    fieldSet: [
      {
        title: '奖池',
        name: 'poor',
        rule: rulePropsMany,
      },
      {
        title: '进度',
        name: 'progress',
        rule: Joi.array()
          .items(
            Joi.object({
              num: Joi.number().integer().min(0).required(),
              reward: Joi.array().items(rulePropLimit).required(),
            }),
          )
          .required(),
      },
      {
        title: '权重',
        name: 'weight',
        rule: ruleArrayWithLine,
      },
    ],
  },
  {
    title: '运营活动-新人七日',
    name: 'activitySevenDay',
    alias: 'sevenDay',
    fieldSet: [
      { title: '任务类型', name: 'type', rule: ruleUnsignedInteger },
      { title: '任务完成条件', name: 'num', rule: ruleUnsignedInteger },
      { title: '对应天数', name: 'day', rule: ruleUnsignedInteger },
      { title: '任务图标', name: 'icon', rule: ruleRequired },
      { title: '任务描述', name: 'desc', rule: ruleRequired },
      { title: '奖励', name: 'reward', rule: rulePropsSingle },
    ],
  },
  {
    title: '运营活动-新人七日总',
    name: 'activitySevenDayTotal',
    alias: 'sevenDayTotal',
    fieldSet: [
      { title: '任务完成条件', name: 'num', rule: ruleUnsignedInteger },
      { title: '奖励', name: 'reward', rule: rulePropsSingle },
    ],
  },
  {
    title: '运营活动-无尽促销',
    name: 'activityEndlessSale',
    alias: 'endlessSale',
    fieldSet: [
      {
        title: '进度',
        name: 'progress',
        rule: Joi.array()
          .items(
            Joi.object({
              num: Joi.number().integer().min(0).required(),
              reward: Joi.array().items(rulePropLimit).required(),
            }),
          )
          .required(),
      },
      {
        title: '道具',
        name: 'sale',
        rule: Joi.array()
          .items(
            Joi.object({
              shopId: Joi.number().integer().allow(null).required(),
              freeReward: Joi.array()
                .items(rulePropLimit)
                .allow(null)
                .required(),
              progressNum: Joi.number().integer().required(),
            }).or('shopId', 'freeReward'),
          )
          .required(),
      },
    ],
  },
  {
    title: '运营活动-每日运营活动',
    name: 'activityDailySale',
    alias: 'dailySale',
    fieldSet: [
      { title: '周几开放', name: 'dayOfWeek', rule: ruleUnsignedInteger },
      { title: '充值道具id', name: 'shopId', rule: ruleUnsignedInteger },
      { title: '付费可获得次数', name: 'buyTimes', rule: ruleUnsignedInteger },
      {
        title: '付费可获得倍数',
        name: 'buyMultipliers',
        rule: ruleUnsignedInteger,
      },
      { title: '奖励', name: 'rewards', rule: rulePropsMany },
      { title: '奖品权重', name: 'weight', rule: ruleArrayWithLine },
    ],
  },
  {
    title: '运营活动-每周运营活动',
    name: 'activityWeeklySale',
    alias: 'weeklySale',
    fieldSet: [
      { title: '充值商品', name: 'shopId', rule: ruleArrayWithLine },
      { title: '购买获得体力', name: 'buyStamina', rule: ruleArrayWithLine },
      {
        title: '免费和广告获得体力',
        name: 'freeStamina',
        rule: ruleUnsignedInteger,
      },
      {
        title: '广告每天次数',
        name: 'adDailyTimes',
        rule: ruleUnsignedInteger,
      },
      {
        title: '进度',
        name: 'progress',
        rule: Joi.array()
          .items(
            Joi.object({
              num: Joi.number().integer().min(0).required(),
              reward: Joi.array().items(rulePropLimit).required(),
            }),
          )
          .required(),
      },
    ],
  },
  {
    title: '运营活动-每周Merge',
    name: 'activityWeeklyMerge',
    alias: 'weeklyMerge',
    fieldSet: [
      { title: '商店1权重', name: 'weight1', rule: ruleArrayWithLine },
      { title: '商店2权重', name: 'weight2', rule: ruleArrayWithLine },
      {
        title: '任务',
        name: 'taskList',
        rule: Joi.array()
          .items(
            Joi.object({
              task: Joi.array()
                .items(
                  Joi.object({
                    icon: Joi.number().integer().required(),
                    num: Joi.number().integer().required(),
                  }),
                )
                .required(),
              reward: Joi.array().items(rulePropLimit).required(),
              progressNum: Joi.number().integer().min(1).required(),
            }),
          )
          .required(),
      },
    ],
  },
  {
    title: '运营活动-每周Treasure',
    name: 'activityWeeklyTreasure',
    alias: 'weeklyTreasure',
    fieldSet: [
      {
        title: '任务',
        name: 'taskList',
        rule: Joi.array()
          .items(
            Joi.object({
              task: Joi.array()
                .items(
                  Joi.object({
                    icon: Joi.number().integer().required(),
                    num: Joi.number().integer().required(),
                  }),
                )
                .required(),
              reward: Joi.array().items(rulePropLimit).required(),
              progressNum: Joi.number().integer().min(1).required(),
            }),
          )
          .required(),
      },
      {
        title: '禁用地图位置',
        name: 'map',
        rule: Joi.array()
          .items(Joi.number().min(0).max(44).integer())
          .unique()
          .required(),
      },
    ],
  },
  {
    title: '运营活动-每日排行榜',
    name: 'activityDailyRanking',
    alias: 'dailyRanking',
    fieldSet: [
      { title: '基础奖励金币', name: 'baseCoin', rule: ruleUnsignedInteger },
      {
        title: '基础奖励金币人数系数 /1000',
        name: 'baseCoinRate1',
        rule: ruleUnsignedInteger,
      },
      {
        title: '基础奖励金币经验系数 /1000',
        name: 'baseCoinRate2',
        rule: ruleUnsignedInteger,
      },
      { title: '基础奖励钻石', name: 'baseDiamond', rule: ruleUnsignedInteger },
      {
        title: '基础奖励钻石人数系数 /1000',
        name: 'baseDiamondRate1',
        rule: ruleUnsignedInteger,
      },
      {
        title: '基础奖励钻石经验系数 /1000',
        name: 'baseDiamondRate2',
        rule: ruleUnsignedInteger,
      },
      { title: '基础奖励Pila', name: 'basePila', rule: ruleUnsignedInteger },
      {
        title: '基础奖励Pila人数系数 /1000',
        name: 'basePilaRate1',
        rule: ruleUnsignedInteger,
      },
      {
        title: '基础奖励Pila经验系数 /1000',
        name: 'basePilaRate2',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: '运营活动-活动集卡',
    name: 'activityHolidayPicture',
    alias: 'HolidayPicture',
    fieldSet: [
      { title: '消耗多少开一格', name: 'expCost', rule: ruleUnsignedInteger },
      { title: '横行1奖励', name: 'rowReward1', rule: rulePropsMany },
      { title: '横行2奖励', name: 'rowReward2', rule: rulePropsMany },
      { title: '横行3奖励', name: 'rowReward3', rule: rulePropsMany },
      { title: '竖列1奖励', name: 'columnReward1', rule: rulePropsMany },
      { title: '竖列2奖励', name: 'columnReward2', rule: rulePropsMany },
      { title: '竖列3奖励', name: 'columnReward3', rule: rulePropsMany },
      { title: '竖列4奖励', name: 'columnReward4', rule: rulePropsMany },
      { title: '进度奖励', name: 'progressReward', rule: rulePropsMany },
    ],
  },
  {
    title: '邮件模板',
    name: categoryNameMailTemplate,
    fieldSet: [{ title: '奖励', name: 'rewards', rule: rulePropsMany }],
  },
  {
    title: 'VIP',
    name: 'vip',
    fieldSet: [
      {
        title: '升级到本级需要多少点数,不累加',
        name: 'point',
        rule: ruleUnsignedInteger,
      },
      {
        title: '到达本级一次性奖励',
        name: 'levelRewards',
        rule: rulePropsMany,
      },
      {
        title: '到达本级钻石购买大礼包',
        name: 'levelPackage',
        rule: ruleUnsignedInteger,
      },
      { title: '到达本级每周奖励', name: 'weekRewards', rule: rulePropsMany },
    ],
  },
  {
    title: '俱乐部配置',
    name: 'clubConfig',
    alias: 'clubConfig',
    fieldSet: [
      { title: '升级所需经验', name: 'exp', rule: ruleInteger },
      {
        title: '自定义规则数量',
        name: 'customizeGameRuleLimit',
        rule: ruleUnsignedInteger,
      },
      { title: '俱乐部人数', name: 'clubMemberUpperLimit', rule: ruleInteger },
      { title: '俱乐部桌子数', name: 'clubDeskUpperLimit', rule: ruleInteger },
      { title: 'roomConf范围', name: 'roomConfIdSet', rule: ruleRequired },
    ],
  },
  {
    title: '拉新奖励',
    name: 'inviteNewcomerAward',
    fieldSet: [
      {
        title: '三方登录奖励pila',
        name: 'bindThirdReward',
        rule: ruleUnsignedInteger,
      },
      { title: '登录3天奖励pila', name: 'login3', rule: ruleUnsignedInteger },
      { title: '登录7天奖励pila', name: 'login7', rule: ruleUnsignedInteger },
      { title: '返现经验系数', name: 'expRate', rule: ruleUnsignedInteger },
      {
        title: '奖励pila总上限',
        name: 'totalLimit',
        rule: ruleUnsignedInteger,
      },
      {
        title: '提现',
        name: 'withdraw',
        rule: Joi.array()
          .items(
            Joi.object({
              propId: Joi.number().integer().required(),
              number: Joi.number().integer().required(),
              onlyOnce: Joi.number().min(0).max(1).required(),
            }),
          )
          .required(),
      },
    ],
  },
  {
    title: '收集每期数据',
    name: 'collectionSeason',
    fieldSet: [
      { title: '每期有几套', name: 'seasonSuitNum', rule: ruleUnsignedInteger },
      {
        title: '每套有几张',
        name: 'suitCardsNum',
        alias: 'suitNum',
        rule: ruleUnsignedInteger,
      },
      { title: '套装奖励', name: 'suitReward', rule: rulePropsMany },
      {
        title: '老虎机抽奖需要碎片数量',
        name: 'slotBetNum',
        rule: rulePropsSingle,
      },
      {
        title: '图标的出现权重  卡包 碎片 万能卡',
        name: 'slotWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '奖励 卡包 碎片 万能卡 随机一张卡走逻辑',
        name: 'slotPayout',
        rule: rulePropsMany,
      },
      {
        title: '老虎机每天免费次数',
        name: 'slotFreeTimes',
        rule: ruleUnsignedInteger,
      },
      {
        title: '老虎机每天广告次数',
        name: 'slotAdTimes',
        rule: ruleUnsignedInteger,
      },
      { title: '进度奖励数量', name: 'progressNum', rule: ruleArrayWithLine },
      { title: '进度奖励物品', name: 'progressReward', rule: rulePropsMany },
    ],
  },
  {
    title: '收集卡片数据',
    name: 'collection',
    fieldSet: [
      {
        title: '卡片分解获得碎片数量',
        name: 'disNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '卡片合成需要万能卡数量',
        name: 'combineNum',
        rule: ruleUnsignedInteger,
      },
      { title: '抽奖权重', name: 'weight', rule: ruleUnsignedInteger },
    ],
  },
  {
    title: '破冰等级礼包',
    name: 'iceBreakerLevelGiftPack',
    alias: 'levelSale',
    fieldSet: [
      {
        title: '商品id',
        name: 'shopId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '等级',
        name: 'level',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: '好友礼物',
    name: 'friendGift',
    fieldSet: [
      {
        title: '基础金币',
        name: 'baseCoinNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '好友送礼金币',
        name: 'friendCoinNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '好友送礼档位',
        name: 'friendRate',
        rule: Joi.array()
          .items(Joi.number().integer().min(0).required())
          .required(),
      },
    ],
  },
  {
    title: '房间配置',
    name: 'room',
    alias: 'gameMode',
    fieldSet: [
      { title: '游戏类型', name: 'gameType', rule: ruleUnsignedInteger },
      {
        title:
          '模式ID:1金币 2是自建房 3是俱乐部 4是俱乐部大厅 5是生存赛金币 6是生存赛pila',
        name: 'modeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '房间支持玩家人数',
        name: 'playerNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '规则id，即对应游戏默认规则的itemId,0为自定义',
        name: 'ruleId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '门票,进入游戏时扣除',
        name: 'tickets',
        rule: ruleUnsignedInteger,
      },
      {
        title: '门票抵用券',
        name: 'ticketCoupon',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '加入的道具下限',
        name: 'joinLimitMin',
        rule: ruleUnsignedInteger,
      },
      {
        title: '加入的道具上限，超过则不允许进入-1代表不限',
        name: 'joinLimitMax',
        rule: ruleInteger,
      },
      {
        title: '强行退出游戏的惩罚',
        name: 'forceQuitGamePenalty',
        rule: ruleUnsignedInteger,
      },
      {
        title: '机器人等级划分',
        name: 'robotLevelDivide',
        rule: /^-?[.\d]+(\|-?[.\d]+){2}$/,
      },
      {
        title: '胜率分档',
        name: 'winPercentageSegmentation',
        rule: ruleArrayWithLine,
      },
    ],
  },
  {
    title: 'canasta-默认规则',
    name: 'gameCanastaDefaultRule',
    fieldSet: [
      { title: '类型', name: 't', rule: ruleUnsignedInteger },
      { title: '是否有dummy', name: 'd', rule: ruleUnsignedInteger },
      { title: '是否有joker', name: 'j', rule: ruleUnsignedInteger },
      { title: 'Hit必须cleanCanastra', name: 'c', rule: ruleUnsignedInteger },
      { title: 'Hit需要拿dummy', name: 'g', rule: ruleUnsignedInteger },
      { title: '是否允许同值', name: 's', rule: ruleUnsignedInteger },
      { title: '摸弃牌堆是否有限制', name: 'l', rule: ruleUnsignedInteger },
      { title: '黑3只能弃', name: 'b', rule: ruleUnsignedInteger },
      { title: '红3自动打', name: 'r', rule: ruleUnsignedInteger },
      { title: '4张红3加分双倍', name: 'f', rule: ruleUnsignedInteger },
      { title: '分数类型1 id', name: 's1', rule: ruleUnsignedInteger },
      { title: '分数类型2 id', name: 's2', rule: ruleUnsignedInteger },
      { title: '分数类型3 id', name: 's3', rule: ruleUnsignedInteger },
      { title: '分数类型4 id', name: 's4', rule: ruleUnsignedInteger },
      { title: '摸牌时间限制', name: 'td', rule: ruleUnsignedInteger },
      { title: '出牌时间限制', name: 'to', rule: ruleUnsignedInteger },
      { title: '单局时间限制', name: 'tg', rule: ruleUnsignedInteger },
    ],
  },
  {
    title: 'canasta-规则分数',
    name: 'gameCanastaRuleScore',
    fieldSet: [
      { title: '类型', name: 'type', rule: ruleUnsignedInteger },
      {
        title: '筛选-所属模式',
        name: 'filterModel',
        rule: ruleUnsignedInteger,
      },
      {
        title: '筛选-开关限制',
        name: 'filterSwitch',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单牌-joker分数',
        name: 'scoreJoker',
        alias: 'score_joker',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单牌-a分数',
        name: 'score1',
        alias: 'score_1',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单牌-2分数',
        name: 'score2',
        alias: 'score_2',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单牌-3-7分数',
        name: 'score3to7',
        alias: 'score_3_7',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单牌-8-k分数',
        name: 'score8to13',
        alias: 'score_8_k',
        rule: ruleUnsignedInteger,
      },
      {
        title: '奖励-dirty',
        name: 'scoreDirtyCanasta',
        alias: 'score_dirty_canasta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '奖励-clean',
        name: 'scoreCleanCanasta',
        alias: 'score_clean_canasta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '奖励-super顺子',
        name: 'scoreSuperCanasta',
        alias: 'score_super_canasta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '奖励-royal顺子',
        name: 'scoreRoyalCanasta',
        alias: 'score_royal_canasta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '奖励-2canastra',
        name: 'scoreSame2Canastra',
        alias: 'score_same_2_canastra',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'truco-默认规则',
    name: 'gameTrucoDefaultRule',
    fieldSet: [
      { title: '类型', name: 't', rule: ruleUnsignedInteger },
      {
        title: '牌的数量，1Dirty全套牌,2Clean纯套牌',
        name: 'd',
        rule: ruleUnsignedInteger,
      },
      { title: '认输是否需要队友确认', name: 'l', rule: ruleUnsignedInteger },
      {
        title: '铁手局是否可以看自己的牌',
        name: 'i',
        rule: ruleUnsignedInteger,
      },
      { title: '出牌时间限制，序号', name: 'to', rule: ruleUnsignedInteger },
      { title: '摸牌时间限制，序号', name: 'tt', rule: ruleUnsignedInteger },
      { title: '单局时间限制，序号', name: 'tg', rule: ruleUnsignedInteger },
    ],
  },
  {
    title: 'cacheta-默认规则',
    name: 'gameCachetaDefaultRule',
    fieldSet: [
      { title: '是否有joker', name: 'j', rule: ruleUnsignedInteger },
      { title: '翻牌确定癞子的方式', name: 'w', rule: ruleUnsignedInteger },
      { title: '是否可以抢胡', name: 'c', rule: ruleUnsignedInteger },
      { title: '弃牌堆癞子能不能摸', name: 'd', rule: ruleUnsignedInteger },
      { title: '听牌是否翻倍', name: 'm', rule: ruleUnsignedInteger },
      { title: '摸牌时间限制，序号', name: 'td', rule: ruleUnsignedInteger },
      { title: '出牌时间限制，序号', name: 'to', rule: ruleUnsignedInteger },
      { title: '抢胡时间限制，序号', name: 'tc', rule: ruleUnsignedInteger },
      { title: '单局时间限制，序号', name: 'tg', rule: ruleUnsignedInteger },
    ],
  },
  {
    title: 'holdem-默认规则',
    name: 'gameHoldemDefaultRule',
    fieldSet: [
      { title: '类型:1 Poker,2 Omaha', name: 't', rule: ruleUnsignedInteger },
      { title: '大盲配置id', name: 'bi', rule: ruleUnsignedInteger },
      { title: '带入倍数配置id', name: 'mi', rule: ruleUnsignedInteger },
      { title: '最大带入的倍数配置id', name: 'ai', rule: ruleUnsignedInteger },
      {
        title: '操作时间限制，序号[1:8,2:12,3:16]',
        name: 'ta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60,2:75,3:90]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'holdem-规则档位',
    name: 'gameHoldemRuleBlind',
    fieldSet: [
      {
        title: '档位类型 1是大盲 2是带入倍数 3是最大是代入的倍数',
        name: 'type',
        rule: ruleUnsignedInteger,
      },
      { title: '数值', name: 'num', rule: ruleUnsignedInteger },
    ],
  },
  {
    title: 'blackjack-默认规则',
    name: 'gameBlackjackDefaultRule',
    fieldSet: [
      {
        title: '操作时间限制，序号[1:8,2:12,3:16]',
        name: 'ta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60,2:75,3:90]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'baccarat-默认规则',
    name: 'gameBaccaratDefaultRule',
    fieldSet: [
      {
        title: '操作时间限制，序号[1:8,2:12,3:16]',
        name: 'ta',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60,2:75,3:90]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'domino-默认规则',
    name: 'gameDominoDefaultRule',
    fieldSet: [
      {
        title: '摸牌时间限制，序号[1:5]',
        name: 'td',
        rule: ruleUnsignedInteger,
      },
      {
        title: '出牌时间限制，序号[1:10]',
        name: 'to',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'backgammon-默认规则',
    name: 'gameBackgammonDefaultRule',
    fieldSet: [
      {
        title: '掷骰子时间限制，序号[1:5]',
        name: 'td',
        rule: ruleUnsignedInteger,
      },
      {
        title: '移动棋子时间限制，序号[1:10]',
        name: 'tm',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'damas-默认规则',
    name: 'gameDamasDefaultRule',
    fieldSet: [
      {
        title: '移动棋子时间限制，序号[1:10]',
        name: 'tm',
        rule: ruleUnsignedInteger,
      },
      {
        title: '单局时间限制，序号[1:60]',
        name: 'tg',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-大转盘配置',
    name: 'miniGameWheel',
    alias: 'miniWheel',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      { title: '转盘倍率,需要/100', name: 'rate', rule: ruleArrayWithLine },
      { title: '获得碎片数量', name: 'fragmentNum', rule: ruleArrayWithLine },
      { title: '概率权重', name: 'weight', rule: ruleArrayWithLine },
      {
        title: '碎片转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingle,
      },
      {
        title: '控制大于倍率,需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-crash配置',
    name: 'miniGameCrash',
    alias: 'miniCrash',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '控制大于倍率,需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-bingo配置',
    name: 'miniGameBingo',
    alias: 'miniBingo',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      { title: '返奖倍率 需要/100', name: 'rate', rule: ruleUnsignedInteger },
      { title: 'jackpot倍率', name: 'jackpotRate', rule: ruleArrayWithLine },
      {
        title: 'jackpot系数配置 需要/1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot奖池倍率控制 需要/100',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
    ],
  },
  {
    title: 'mini-videoPoker配置',
    name: 'miniGameVideoPoker',
    alias: 'miniVideoPoker',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '不同区域返奖倍率 需要/100',
        name: 'rate',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-PiggyBank配置',
    name: 'miniPiggyBank',
    alias: 'miniPiggyBank',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '普通列1 权重 需要/1000',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2 权重 需要/1000',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3 权重 需要/1000',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4 权重 需要/1000',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5 权重 需要/1000',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1 权重 需要/1000',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2 权重 需要/1000',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3 权重 需要/1000',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4 权重 需要/1000',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5 权重 需要/1000',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '3个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot权重',
        name: 'jackpotWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot奖池倍率控制 需要/100',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot中奖位置权重 第3-10个位置',
        name: 'jackpotPosWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-mines配置',
    name: 'miniGameMines',
    alias: 'miniMines',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Ball配置',
    name: 'miniGameBall',
    alias: 'miniBall',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '不同区域返奖倍率 需要/100',
        name: 'rate',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Niubi配置',
    name: 'miniGameNiubi',
    alias: 'miniNiubi',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '倍率 需要/100 黄/红/蓝/any',
        name: 'rate',
        rule: ruleArrayWithLine,
      },
      {
        title: '图标的出现权重 空/黄/红/蓝',
        name: 'weight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-SicBo配置',
    name: 'miniGameSicBo',
    alias: 'miniSicBo',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
    ],
  },
  {
    title: 'mini-Slicer配置',
    name: 'miniGameSlicer',
    alias: 'miniSlicer',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      { title: '倍率 需要/100', name: 'rate', rule: ruleArrayWithLine },
    ],
  },
  {
    title: 'mini-Keno配置',
    name: 'miniGameKeno',
    alias: 'miniKeno',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      { title: '倍率1 需要/100', name: 'rate1', rule: ruleArrayWithLine },
      { title: '倍率2 需要/100', name: 'rate2', rule: ruleArrayWithLine },
      { title: '倍率3 需要/100', name: 'rate3', rule: ruleArrayWithLine },
      { title: '倍率4 需要/100', name: 'rate4', rule: ruleArrayWithLine },
      { title: '倍率5 需要/100', name: 'rate5', rule: ruleArrayWithLine },
      { title: '倍率6 需要/100', name: 'rate6', rule: ruleArrayWithLine },
      { title: '倍率7 需要/100', name: 'rate7', rule: ruleArrayWithLine },
      { title: '倍率8 需要/100', name: 'rate8', rule: ruleArrayWithLine },
      { title: '倍率9 需要/100', name: 'rate9', rule: ruleArrayWithLine },
      { title: '倍率10 需要/100', name: 'rate10', rule: ruleArrayWithLine },
    ],
  },
  {
    title: 'mini-CandyBlitz配置',
    name: 'miniGameCandyBlitz',
    alias: 'miniCandyBlitz',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '8-9个图标赔率 需要/100',
        name: 'payout1',
        rule: ruleArrayWithLine,
      },
      {
        title: '10-11个图标赔率 需要/100',
        name: 'payout2',
        rule: ruleArrayWithLine,
      },
      {
        title: '12-30个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重', name: 'commonWeight', rule: ruleArrayWithLine },
      { title: '免费权重', name: 'freeWeight', rule: ruleArrayWithLine },
      { title: '多倍倍率表', name: 'multiplier', rule: ruleArrayWithLine },
      {
        title: '普通阶段多倍权重',
        name: 'commonMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费阶段多倍权重',
        name: 'freeMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Olypus配置',
    name: 'miniGameOlypus',
    alias: 'miniOlypus',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '8-9个图标赔率 需要/100',
        name: 'payout1',
        rule: ruleArrayWithLine,
      },
      {
        title: '10-11个图标赔率 需要/100',
        name: 'payout2',
        rule: ruleArrayWithLine,
      },
      {
        title: '12-30个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重', name: 'commonWeight', rule: ruleArrayWithLine },
      { title: '免费权重', name: 'freeWeight', rule: ruleArrayWithLine },
      { title: '多倍倍率表', name: 'multiplier', rule: ruleArrayWithLine },
      {
        title: '普通阶段多倍权重',
        name: 'commonMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费阶段多倍权重',
        name: 'freeMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
      {
        title: '5个jackpot权重',
        name: 'jackpotWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot奖池倍率控制 需要/100',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
    ],
  },
  {
    title: 'mini-CandyMegaway配置',
    name: 'miniGameCandyMegaway',
    alias: 'miniCandyMegaway',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100',
        name: 'payout',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重1', name: 'commonWeight1', rule: ruleArrayWithLine },
      { title: '普通权重2', name: 'commonWeight2', rule: ruleArrayWithLine },
      { title: '普通权重3', name: 'commonWeight3', rule: ruleArrayWithLine },
      { title: '免费权重1', name: 'freeWeight1', rule: ruleArrayWithLine },
      { title: '免费权重2', name: 'freeWeight2', rule: ruleArrayWithLine },
      { title: '免费权重3', name: 'freeWeight3', rule: ruleArrayWithLine },
      {
        title: '列图标个数几率,2-8个图标',
        name: 'cNumWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '多倍倍率表,只有免费有多倍',
        name: 'multiplier',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费阶段多倍权重',
        name: 'freeMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-DogMegaway配置',
    name: 'miniGameDogMegaway',
    alias: 'miniDogMegaway',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重1', name: 'commonWeight1', rule: ruleArrayWithLine },
      { title: '普通权重2', name: 'commonWeight2', rule: ruleArrayWithLine },
      { title: '普通权重3', name: 'commonWeight3', rule: ruleArrayWithLine },
      { title: '普通权重4', name: 'commonWeight4', rule: ruleArrayWithLine },
      { title: '普通权重5', name: 'commonWeight5', rule: ruleArrayWithLine },
      { title: '普通权重6', name: 'commonWeight6', rule: ruleArrayWithLine },
      { title: '免费权重1', name: 'freeWeight1', rule: ruleArrayWithLine },
      { title: '免费权重2', name: 'freeWeight2', rule: ruleArrayWithLine },
      { title: '免费权重3', name: 'freeWeight3', rule: ruleArrayWithLine },
      { title: '免费权重4', name: 'freeWeight4', rule: ruleArrayWithLine },
      { title: '免费权重5', name: 'freeWeight5', rule: ruleArrayWithLine },
      { title: '免费权重6', name: 'freeWeight6', rule: ruleArrayWithLine },
      {
        title: '列图标个数几率,2-7个图标',
        name: 'cNumWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-BuffaloMegaway配置',
    name: 'miniGameBuffaloMegaway',
    alias: 'miniBuffaloMegaway',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重1', name: 'commonWeight1', rule: ruleArrayWithLine },
      { title: '普通权重2', name: 'commonWeight2', rule: ruleArrayWithLine },
      { title: '普通权重3', name: 'commonWeight3', rule: ruleArrayWithLine },
      { title: '普通权重4', name: 'commonWeight4', rule: ruleArrayWithLine },
      { title: '普通权重5', name: 'commonWeight5', rule: ruleArrayWithLine },
      { title: '普通权重6', name: 'commonWeight6', rule: ruleArrayWithLine },
      {
        title: '普通横向图标权重',
        name: 'commonHorizontalWeight',
        rule: ruleArrayWithLine,
      },
      { title: '免费权重1', name: 'freeWeight1', rule: ruleArrayWithLine },
      { title: '免费权重2', name: 'freeWeight2', rule: ruleArrayWithLine },
      { title: '免费权重3', name: 'freeWeight3', rule: ruleArrayWithLine },
      { title: '免费权重4', name: 'freeWeight4', rule: ruleArrayWithLine },
      { title: '免费权重5', name: 'freeWeight5', rule: ruleArrayWithLine },
      { title: '免费权重6', name: 'freeWeight6', rule: ruleArrayWithLine },
      {
        title: '免费横向图标权重',
        name: 'freeHorizontalWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '列图标个数几率,2-7个图标',
        name: 'cNumWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-AztecMegaway配置',
    name: 'miniGameAztecMegaway',
    alias: 'miniAztecMegaway',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重1', name: 'commonWeight1', rule: ruleArrayWithLine },
      { title: '普通权重2', name: 'commonWeight2', rule: ruleArrayWithLine },
      { title: '普通权重3', name: 'commonWeight3', rule: ruleArrayWithLine },
      { title: '普通权重4', name: 'commonWeight4', rule: ruleArrayWithLine },
      { title: '普通权重5', name: 'commonWeight5', rule: ruleArrayWithLine },
      { title: '普通权重6', name: 'commonWeight6', rule: ruleArrayWithLine },
      { title: '免费权重1', name: 'freeWeight1', rule: ruleArrayWithLine },
      { title: '免费权重2', name: 'freeWeight2', rule: ruleArrayWithLine },
      { title: '免费权重3', name: 'freeWeight3', rule: ruleArrayWithLine },
      { title: '免费权重4', name: 'freeWeight4', rule: ruleArrayWithLine },
      { title: '免费权重5', name: 'freeWeight5', rule: ruleArrayWithLine },
      { title: '免费权重6', name: 'freeWeight6', rule: ruleArrayWithLine },
      {
        title: '列图标个数几率,2-7个图标',
        name: 'cNumWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '空+5个jackpot权重',
        name: 'jackpotWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot奖池倍率控制 需要/100',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
      {
        title: 'holdSpin倍率 需要/100',
        name: 'holdRate',
        rule: ruleArrayWithLine,
      },
      { title: 'holdSpin权重', name: 'holdWeight', rule: ruleArrayWithLine },
    ],
  },
  {
    title: 'mini-Merge配置',
    name: 'miniGameMergeUp',
    alias: 'miniMerge',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '4,5个图标合成赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '6,7个图标合成赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '8,9个图标合成赔率 需要/100',
        name: 'payout8',
        rule: ruleArrayWithLine,
      },
      {
        title: '10个及以上图标合成赔率 需要/100',
        name: 'payout10',
        rule: ruleArrayWithLine,
      },
      { title: '普通权重', name: 'commonWeight', rule: ruleArrayWithLine },
      { title: '免费权重', name: 'freeWeight', rule: ruleArrayWithLine },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Plinko配置',
    name: 'miniGamePlinko',
    alias: 'miniPlinko',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      { title: '倍率 需要/100', name: 'rate', rule: ruleArrayWithLine },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Roulette配置',
    name: 'miniGameRoulette',
    alias: 'miniRoulette',
    fieldSet: [
      { title: '房间模式id', name: 'roomModeId', rule: ruleUnsignedInteger },
      { title: '下注金额', name: 'betNum', rule: ruleUnsignedInteger },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
    ],
  },
  {
    title: 'mini-Roma配置',
    name: 'miniGameRoma',
    alias: 'miniRoma',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '7个图标赔率 需要/100',
        name: 'payout7',
        rule: ruleArrayWithLine,
      },
      {
        title: '8个图标赔率 需要/100',
        name: 'payout8',
        rule: ruleArrayWithLine,
      },
      {
        title: '9个图标赔率 需要/100',
        name: 'payout9',
        rule: ruleArrayWithLine,
      },
      {
        title: '10个图标赔率 需要/100',
        name: 'payout10',
        rule: ruleArrayWithLine,
      },
      {
        title: '11个图标赔率 需要/1000',
        name: 'payout11',
        rule: ruleArrayWithLine,
      },
      {
        title: '12个图标赔率 需要/1000',
        name: 'payout12',
        rule: ruleArrayWithLine,
      },
      {
        title: '13个图标赔率 需要/1000',
        name: 'payout13',
        rule: ruleArrayWithLine,
      },
      {
        title: '14个图标赔率 需要/1000',
        name: 'payout14',
        rule: ruleArrayWithLine,
      },
      {
        title: '15个图标赔率 需要/1000',
        name: 'payout15',
        rule: ruleArrayWithLine,
      },
      {
        title: '转盘倍率表',
        name: 'payoutRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '转盘倍率权重',
        name: 'payoutRateWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '图标权重  普通和免费阶段通用',
        name: 'weight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-African配置',
    name: 'miniGameAfrican',
    alias: 'miniAfrican',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Infinity配置',
    name: 'miniGameInfinity',
    alias: 'miniInfinity',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '图标基础赔率 需要/100',
        name: 'payout',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重1  10图标 11是wild 12是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重2',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重3',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重4 触发列',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重1',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重2',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重3',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重4 触发列',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Muerto配置',
    name: 'miniGameMuerto',
    alias: 'miniMuerto',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通扩展列数量权重',
        name: 'commonExpandWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费扩展列数量权重',
        name: 'freeExpandWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Horse配置',
    name: 'miniGameHorse',
    alias: 'miniHorse',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-MegaSymbol配置',
    name: 'miniGameMegaSymbol',
    alias: 'miniMegaSymbol',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 1-3为megesymbol 4-10为普通图标 11为wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通转盘免费次数',
        name: 'wheelFreeSpin',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费转盘奖励倍数',
        name: 'wheelReward',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100 对应在转盘奖励倍率中找相同的',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通转盘免费次数权重',
        name: 'wheelFreeSpinWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费转盘奖励权重',
        name: 'wheelRewardWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 ',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重 ',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重 ',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '普通女郎权重1 第一位代表不中 后续每5为代表一个图标的5种情况：上1 上2 全 下2 下1',
        name: 'commonMegaSymbolWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通女郎权重2',
        name: 'commonMegaSymbolWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通女郎权重3',
        name: 'commonMegaSymbolWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通女郎权重4',
        name: 'commonMegaSymbolWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通女郎权重5',
        name: 'commonMegaSymbolWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '免费女郎权重1 第一位代表不中 后续每5为代表一个图标的5种情况：上1 上2 全 下2 下1',
        name: 'freeMegaSymbolWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费女郎权重2',
        name: 'freeMegaSymbolWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费女郎权重3',
        name: 'freeMegaSymbolWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费女郎权重4',
        name: 'freeMegaSymbolWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费女郎权重5',
        name: 'freeMegaSymbolWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title:
          'jackpot奖池倍率控制 需要/100 系数增加部分充满可开启对应档位jackpot 相当于jackpot基础部分满足抽水',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-SplitSymbol配置',
    name: 'miniGameSplitSymbol',
    alias: 'miniSplitSymbol',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 10是wild 11是scatter',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '8个图标赔率 需要/100',
        name: 'payout8',
        rule: ruleArrayWithLine,
      },
      {
        title: '10个图标赔率 需要/100',
        name: 'payout10',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通分裂图标随机 1-9',
        name: 'commonSplitSymbolWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通分裂形式随机 横2|竖2|横竖2',
        name: 'commonSplitTypeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 ',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费分裂图标随机 1-9',
        name: 'freeSplitSymbolWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费分裂形式随机 横2|竖2|横竖2',
        name: 'freeSplitTypeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-MysterySymbol配置',
    name: 'miniGameMysterySymbol',
    alias: 'miniMysterySymbol',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 10是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-9普通图标 10wild 11scatter 12神秘图标',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通神秘图标随机 1-9',
        name: 'commonMysterySymbolWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通神秘图标形式随机 横1/横2',
        name: 'commonMysteryTypeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 ',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费神秘图标随机 1-9',
        name: 'freeMysterySymbolWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费神秘图标形式随机 横1/横2',
        name: 'freeMysteryTypeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-BonusSymbol配置',
    name: 'miniGameBonusSymbol',
    alias: 'miniBonusSymbol',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 10是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-11普通图标 12wild 13-15奖励图标',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: 'holdspin倍率 需要/100',
        name: 'holdRate',
        rule: ruleArrayWithLine,
      },
      {
        title: 'holdspin权重',
        name: 'holdWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '奖励图标进度百分比 /10000',
        name: 'progress',
        rule: ruleArrayWithLine,
      },
      {
        title: '奖励图标触发几率 /10000',
        name: 'bonusRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '多倍倍率',
        name: 'multiplier',
        rule: ruleArrayWithLine,
      },
      {
        title: '多倍权重',
        name: 'multiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-CollectionSymbol配置',
    name: 'miniGameCollectionSymbol',
    alias: 'miniCollectionSymbol',
    fieldSet: [
      {
        title: '房间模式id',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '使用劵转一次的消耗',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 10是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-9普通图标 10是矿石图标',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '收集倍率 需要/100',
        name: 'collectionRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '收集倍率权重',
        name: 'collectionWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通wild几率  /10000',
        name: 'commonWildWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费图标进度百分比 /10000',
        name: 'progress',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费图标触发几率 /10000',
        name: 'freeRate',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Pop配置',
    name: 'miniGamePop',
    alias: 'miniPop',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 图标1-8+wild 低级图标是5-8',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '7个图标赔率 需要/100',
        name: 'payout7',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-8普通图标 9是wild 10是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列6权重',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列7权重',
        name: 'commonWeight7',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 1-8普通图标 9是wild 10是闪电',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列6权重',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列7权重',
        name: 'freeWeight7',
        rule: ruleArrayWithLine,
      },
      {
        title: 'scatter数量',
        name: 'scatterNum',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费次数',
        name: 'freeTimes',
        rule: ruleArrayWithLine,
      },
      {
        title: '闪电消除图标个数 ',
        name: 'lightningRemoveNum',
        rule: ruleArrayWithLine,
      },
      {
        title: '闪电消除图标权重',
        name: 'lightningRemoveWeight',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-HoneyRush配置',
    name: 'miniGameHoneyRush',
    alias: 'miniHoneyRush',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '5个图标赔率 需要/100 普通图标1-8',
        name: 'payout1',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout2',
        rule: ruleArrayWithLine,
      },
      {
        title: '7个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '8个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '9个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '10-14个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '15-19个图标赔率 需要/100',
        name: 'payout7',
        rule: ruleArrayWithLine,
      },
      {
        title: '20-24个图标赔率 需要/100',
        name: 'payout8',
        rule: ruleArrayWithLine,
      },
      {
        title: '25-29个图标赔率 需要/100',
        name: 'payout9',
        rule: ruleArrayWithLine,
      },
      {
        title: '30-37个图标赔率 需要/100',
        name: 'payout10',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重 ',
        name: 'commonWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '效果触发对应消除图标个数 wild也算个数',
        name: 'triggerNum',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-DoubleCommon配置',
    name: 'miniGameDoubleCommon',
    alias: 'miniDoubleCommon',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 1-10普通图标 11是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '普通列1权重  1-10普通图标 11wild 12scatter 1-5为上方盘面 6-10为下方盘面',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列6权重 下方盘面',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列7权重',
        name: 'commonWeight7',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列8权重',
        name: 'commonWeight8',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列9权重',
        name: 'commonWeight9',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列10权重',
        name: 'commonWeight10',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 ',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-DoubleFree配置',
    name: 'miniGameDoubleFree',
    alias: 'miniDoubleFree',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 1-10普通图标 11是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-10免费图标 11wild 12scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '免费列1权重  1-10免费图标 11wild 12scatter 1-5为左边盘面 6-10为右边盘面',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重 ',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重 ',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列6权重 右方盘面',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列7权重',
        name: 'freeWeight7',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列8权重',
        name: 'freeWeight8',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列9权重',
        name: 'freeWeight9',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列10权重',
        name: 'freeWeight10',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-BingoSlot配置',
    name: 'miniGameBingoSlot',
    alias: 'miniBingoSlot',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '奖励图标奖励倍率 /100',
        name: 'bonusReward',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100 对应在转盘奖励倍率中找相同的',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费转盘奖励权重',
        name: 'bonusRewardWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-10为普通图标 11是奖励图标 12是scatter图标',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 1-10为普通图标 11是奖励图标 12是scatter图标',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重 ',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title:
          'jackpot奖池倍率控制 需要/100 系数增加部分充满可开启对应档位jackpot 相当于jackpot基础部分满足抽水',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Nudge配置',
    name: 'miniGameNudge',
    alias: 'miniNudge',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '8-9个图标赔率 需要/100 普通图标1-10',
        name: 'payout1',
        rule: ruleArrayWithLine,
      },
      {
        title: '10-11个图标赔率 需要/100',
        name: 'payout2',
        rule: ruleArrayWithLine,
      },
      {
        title: '12个及以上图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: 'scatter数量',
        name: 'scatterNum',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费次数',
        name: 'freeTimes',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重 1-10普通图标 11是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列6权重',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 1-10普通图标 11是多倍',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列6权重',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '多倍倍率表',
        name: 'multiplier',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费多倍倍率权重',
        name: 'freeMultiplierWeight',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-RainbowReel配置',
    name: 'miniGameRainbowReel',
    alias: 'miniRainbowReel',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 普通图标1-11 12为wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重  普通图标1-11 12为wild 13为scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 ',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重 ',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重 ',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重 ',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重 ',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Tiki配置',
    name: 'miniGameTiki',
    alias: 'miniTiki',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 普通图标1-11',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列1权重  普通图标1-11 12为scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列2权重 ',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列3权重',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列4权重 ',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列5权重',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通列6权重',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列1权重 ',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列2权重 ',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列3权重 ',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列4权重 ',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列5权重 ',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费列6权重 ',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Giza配置',
    name: 'miniGameGiza',
    alias: 'miniGiza',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 普通图标1-6 7为wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通图标权重1-6是普通图标 7是wild 8是scatter',
        name: 'commonWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费图标权重 ',
        name: 'freeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-RuneCraft配置',
    name: 'miniGameRuneCraft',
    alias: 'miniRuneCraft',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3个图标赔率 需要/100 普通图标1-7 8为wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot倍率 /100',
        name: 'jackpotRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通图标权重1-7是普通图标 8是wild',
        name: 'commonWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '效果权重 1-3是效果权重 4是空的权重',
        name: 'effectWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot权重',
        name: 'jackpotWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '5个jackpot增加比例 /1000',
        name: 'jackpotRatio',
        rule: ruleArrayWithLine,
      },
      {
        title:
          'jackpot奖池倍率控制 需要/100 系数增加部分充满可开启对应档位jackpot 相当于jackpot基础部分满足抽水',
        name: 'jackpotControl',
        rule: ruleArrayWithLine,
      },
      {
        title: 'jackpot中奖位置权重 第3-10个位置',
        name: 'jackpotPosWeight',
        rule: ruleArrayWithLine,
      },
      {
        title:
          '控制大于倍率 需要/100 大于此倍率的返奖如果奖池不够赔付则重新随机一次 如果够赔付则赔付后奖池清0',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-Fireworks配置',
    name: 'miniGameFireworks',
    alias: 'miniFireworks',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100 11是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重1  10图标 11是wild 12是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重2',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重3',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重4',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重5',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重6',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重7 横向 ',
        name: 'commonHorizontalWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重1',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重2',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重3',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重4',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重5',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重6',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重7 横向',
        name: 'freeHorizontalWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '列图标个数几率 2-6',
        name: 'cNumWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-VikingMadness配置',
    name: 'miniGameVikingMadness',
    alias: 'miniVikingMadness',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100 11是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重1  10图标 11是wild 12是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重2',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重3',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重4',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重5',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重1',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重2',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重3',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重4',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重5',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-TombOfGold配置',
    name: 'miniGameTombOfGold',
    alias: 'miniTombOfGold',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100 11是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重1  10图标 11是wild 12是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重2',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重3',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重4',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重5',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重6',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重1',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重2',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重3',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重4',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重5',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重6',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: 'holdspin倍率 需要/100',
        name: 'holdRate',
        rule: ruleArrayWithLine,
      },
      {
        title: 'holdspin权重',
        name: 'holdWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-SweetKingdom配置',
    name: 'miniGameSweetKingdom',
    alias: 'miniSweetKingdom',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '5个图标赔率 需要/100',
        name: 'payout1',
        rule: ruleArrayWithLine,
      },
      {
        title: '6个图标赔率 需要/100',
        name: 'payout2',
        rule: ruleArrayWithLine,
      },
      {
        title: '7个图标赔率 需要/100',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '8个图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '9个图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '10个图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '11个图标赔率 需要/100',
        name: 'payout7',
        rule: ruleArrayWithLine,
      },
      {
        title: '12个图标赔率 需要/100',
        name: 'payout8',
        rule: ruleArrayWithLine,
      },
      {
        title: '13个图标赔率 需要/100',
        name: 'payout9',
        rule: ruleArrayWithLine,
      },
      {
        title: '14个图标赔率 需要/100',
        name: 'payout10',
        rule: ruleArrayWithLine,
      },
      {
        title: '15+个图标赔率 需要/100',
        name: 'payout11',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重  1-7普通图标 8是scatter 9是money 10是collection',
        name: 'commonWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重',
        name: 'freeWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '收集倍率 需要/100',
        name: 'moneyRate',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通倍率权重',
        name: 'commonMoneyWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费倍率权重',
        name: 'freeMoneyWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
  {
    title: 'mini-AztecBlaze配置',
    name: 'miniGameAztecBlaze',
    alias: 'miniAztecBlaze',
    fieldSet: [
      {
        title: '房间类型',
        name: 'roomModeId',
        rule: ruleUnsignedInteger,
      },
      {
        title: '下注档位',
        name: 'betNum',
        rule: ruleUnsignedInteger,
      },
      {
        title: '几个券转一次',
        name: 'fragmentOnce',
        rule: rulePropsSingleAndNullable,
      },
      {
        title: '3列图标赔率 需要/100 9是wild',
        name: 'payout3',
        rule: ruleArrayWithLine,
      },
      {
        title: '4列图标赔率 需要/100',
        name: 'payout4',
        rule: ruleArrayWithLine,
      },
      {
        title: '5列图标赔率 需要/100',
        name: 'payout5',
        rule: ruleArrayWithLine,
      },
      {
        title: '6列图标赔率 需要/100',
        name: 'payout6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通合并列概率 无|2列|3列|4列',
        name: 'commonCombine',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费合并列概率 无|2列|3列|4列',
        name: 'freeCombine',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重1  8图标 9是wild 10是scatter',
        name: 'commonWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重2',
        name: 'commonWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重3',
        name: 'commonWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重4',
        name: 'commonWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重5',
        name: 'commonWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通权重6',
        name: 'commonWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '普通巨型图标权重',
        name: 'commonMegaWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重1',
        name: 'freeWeight1',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重2',
        name: 'freeWeight2',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重3',
        name: 'freeWeight3',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重4',
        name: 'freeWeight4',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重5',
        name: 'freeWeight5',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费权重6',
        name: 'freeWeight6',
        rule: ruleArrayWithLine,
      },
      {
        title: '免费巨型图标权重',
        name: 'freeMegaWeight',
        rule: ruleArrayWithLine,
      },
      {
        title: '控制大于倍率 需要/100',
        name: 'riskRate',
        rule: ruleUnsignedInteger,
      },
    ],
  },
];

export const systemConfigMap: { [key: string]: ISystemConfigItem } =
  lodash.keyBy(systemConfig, 'name');

function systemConfigOptions(
  categoryName: string,
  options?: number[],
): Promise<number[]> {
  return new Promise<number[]>((resolve, reject) => {
    if (options && options.length > 0) {
      resolve(options);
      return;
    }
    Fetch.get<number[]>(API_URLS.systemConfigItemIdSet, { categoryName })
      .then((data) => {
        resolve(data);
      })
      .catch(reject);
  });
}

export function systemConfigProps(options?: number[]): Promise<number[]> {
  return systemConfigOptions(categoryNameProps, options);
}

export function systemConfigMailTemplate(
  options?: number[],
): Promise<number[]> {
  return systemConfigOptions(categoryNameMailTemplate, options);
}
