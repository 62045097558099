import axios, { AxiosRequestConfig } from 'axios';
import { LOGIN_TOKEN_KEY } from '@/constants/common';
import { message } from 'antd';

const prodApiDomain = 'https://api.buracook.com';

const domain = ((): string => {
  const apiUrl = process.env.REACT_APP_API_URL;
  if (apiUrl) {
    return apiUrl;
  }
  if (/op-test\.buracook\.com/.test(window.location.host)) {
    return 'https://api-test.buracook.com';
  }
  if (/op-uat\.buracook\.com/.test(window.location.host)) {
    return 'https://api-uat.buracook.com';
  }
  if (/op\.buracook\.com/.test(window.location.host)) {
    return prodApiDomain;
  }
  return 'http://192.168.21.130:9501';
})();

export const isProd = domain == prodApiDomain;

class FetchError extends Error {
  public readonly name: string;
  public readonly code: string;
  public readonly data: any;
  public readonly msg: string;

  constructor(code: string, data: any, msg: string) {
    super(msg);
    this.name = this.constructor.name;
    this.code = code;
    this.data = data;
    this.msg = msg;
  }
}

export default class Fetch {
  /**
   * 配置如 sso ref 等通用参数
   * @param params
   * @returns {}
   */
  protected static _setCommonParams(params: {}) {
    return params;
  }

  protected static _request<T = any>(
    url: string,
    userOptions: AxiosRequestConfig<any>,
  ) {
    const defaultOptions = {
      headers: {
        Accept: 'application/json, */*',
        Authorization: window.localStorage.getItem(LOGIN_TOKEN_KEY),
        'Content-Type': 'application/json',
      },
    };
    return new Promise<T>((resolve, reject) => {
      const hideLoading = message.loading('loading', 0);
      axios(`${domain}${url}`, {
        ...defaultOptions,
        ...userOptions,
      })
        .then((response) => {
          hideLoading();
          const status = response.status;
          if (status === 200) {
            switch (response.data.code) {
              case 'Success':
                resolve(response.data.data);
                return;
              case 'Unauthenticated': //未登录，直接刷新页面会出现登录框
                window.localStorage.removeItem(LOGIN_TOKEN_KEY);
                window.location.reload();
                break;
              default:
                reject(
                  new FetchError(
                    response.data.code,
                    response.data.data,
                    response.data.msg,
                  ),
                );
                return;
            }
          }
          if (status === 204) {
            resolve({} as T);
            return;
          }
          let errMsg = '';
          if (status < 200) {
            errMsg = 'Continue ...';
          } else if (status >= 400 && status < 500) {
            switch (status) {
              case 400:
                errMsg = 'Bad Request';
                break;
              case 401:
                errMsg = 'Unauthorized';
                break;
              case 404:
                errMsg = 'Not Found';
                break;
              case 408:
                errMsg = 'Request Time-out';
                break;
              default:
                errMsg = 'Permission Refused';
                break;
            }
          } else {
            switch (status) {
              case 500:
                errMsg = 'Internal Server Error';
                break;
              case 501:
                errMsg = 'Not Implemented';
                break;
              case 502:
                errMsg = 'Bad Gateway';
                break;
              case 503:
                errMsg = 'Service Unavailable';
                break;
              case 504:
                errMsg = 'Gateway Time-out';
                break;
              case 505:
                errMsg = 'HTTP Version not supported';
                break;
              default:
                errMsg = 'Unknown Error';
                break;
            }
          }
          reject(
            new FetchError(
              `HTTP ERROR ${status}:${errMsg}`,
              response.data,
              errMsg,
            ),
          );
        })
        .catch((e) => {
          hideLoading();
          reject(e);
        });
    });
  }

  static get<T = any>(url: string, data = {}) {
    const params = Fetch._setCommonParams(data);
    return Fetch._request<T>(url, {
      params: { ...params },
      method: 'get',
    });
  }

  static post<T = any>(url: string, data = {}) {
    const params = Fetch._setCommonParams(data);
    return Fetch._request<T>(url, {
      data: JSON.stringify({ ...params }),
      method: 'post',
    });
  }
}
