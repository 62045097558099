// @ts-ignore
import { systemConfig } from '@/constants/systemConfig';
import User, { rule } from '@/utils/user';
import lodash from 'lodash';

const ROOT = '/';

export interface IRoute {
  id: string;
  label: string;
  path?: string;
  tsxPath?: string;
  childRoutes?: IRoute[];
  parentId?: string;
  disabledRuleIdSet?: rule[]; //禁用本路由功能的角色id
}

const ORIGINAL_ROUTES: IRoute[] = [
  //这是原始路由，后面导出的ROUTES是根据权限重新分配的路由
  {
    id: 'home',
    path: ROOT,
    label: '首页',
    tsxPath: 'home/index.tsx',
  },
  {
    id: 'system-data',
    label: '数据配置',
    disabledRuleIdSet: [rule.operations, rule.customerService],
    childRoutes: systemConfig.map((config) => ({
      id: `system-data-config-${config.name}`,
      path: `${ROOT}system/data/config/${config.name}`,
      label: config.title,
      tsxPath: 'system/data/config.tsx',
    })),
  },
  {
    id: 'system',
    label: '系统管理',
    disabledRuleIdSet: [rule.operations, rule.customerService],
    childRoutes: [
      {
        id: 'system-app-version',
        path: `${ROOT}system/appVersion`,
        label: '版本管理',
        tsxPath: 'system/appVersion.tsx',
      },
      {
        id: 'system-app-config',
        path: `${ROOT}system/appConfig`,
        label: 'app数据配置',
        tsxPath: 'system/appConfig.tsx',
      },
      {
        id: 'system-inner-device',
        path: `${ROOT}system/innerDevice`,
        label: '内部设备列表',
        tsxPath: 'system/innerDevice.tsx',
      },
      {
        id: 'system-app-bulletin',
        path: `${ROOT}system/appBulletin`,
        label: '全局公告',
        tsxPath: 'system/appBulletin.tsx',
      },
      {
        id: 'system-notice',
        path: `${ROOT}system/notice`,
        label: '全局通知',
        tsxPath: 'system/notice.tsx',
      },
      {
        id: 'system-mail',
        path: `${ROOT}system/mail`,
        label: '发送邮件',
        tsxPath: 'system/mail.tsx',
      },
    ],
  },
  {
    id: 'activity',
    path: `${ROOT}activity`,
    label: '运营活动',
    tsxPath: 'activity/index.tsx',
    childRoutes: [
      {
        id: 'activity-create',
        path: `${ROOT}activity/create`,
        label: '创建运营活动',
        tsxPath: 'activity/detail/index.tsx',
      },
      {
        id: 'activity-edit',
        path: `${ROOT}activity/edit/:id`,
        label: '修改运营活动',
        tsxPath: 'activity/detail/index.tsx',
      },
      {
        id: 'activity-clone',
        path: `${ROOT}activity/clone/:id`,
        label: '复制运营活动',
        tsxPath: 'activity/detail/index.tsx',
      },
    ],
  },
  {
    id: 'user',
    label: '用户管理',
    childRoutes: [
      {
        id: 'user-list',
        path: `${ROOT}user/list`,
        label: '用户列表',
        tsxPath: 'user/list.tsx',
      },
      {
        id: 'user-active-history',
        path: `${ROOT}user/activeHistory`,
        label: '活跃历史',
        tsxPath: 'user/activeHistory.tsx',
      },
      {
        id: 'user-access-limit',
        path: `${ROOT}user/accessLimit`,
        label: '访问限制',
        tsxPath: 'user/limitedAccess.tsx',
      },
    ],
  },
  {
    id: 'club',
    label: '俱乐部管理',
    disabledRuleIdSet: [rule.customerService],
    childRoutes: [
      {
        id: 'club-apply',
        path: `${ROOT}club/apply`,
        label: '申请列表',
        tsxPath: 'club/apply.tsx',
      },
      {
        id: 'club-list',
        path: `${ROOT}club/list`,
        label: '俱乐部列表',
        tsxPath: 'club/list.tsx',
      },
    ],
  },
  {
    id: 'order',
    path: `${ROOT}order`,
    label: '订单管理',
    disabledRuleIdSet: [rule.operations, rule.customerService],
    tsxPath: 'order/index.tsx',
  },
  {
    id: 'localOperation',
    path: `${ROOT}localOperation`,
    label: '本地化运营',
    disabledRuleIdSet: [rule.operations, rule.customerService],
    childRoutes: [
      {
        id: 'promotionCode-list',
        path: `${ROOT}localOperation/promotionCode/list`,
        label: '推广链接',
        tsxPath: 'localOperation/promotionCode/list.tsx',
      },
      {
        id: 'newcomerAgent-list',
        path: `${ROOT}localOperation/newcomerAgent/list`,
        label: '拉新-代理管理',
        tsxPath: 'localOperation/newcomerAgent/list.tsx',
      },
    ],
  },
  {
    id: 'tools',
    label: '工具',
    childRoutes: [
      {
        id: 'tools-game-log',
        path: `${ROOT}tools/gameLog`,
        label: '游戏回放',
        tsxPath: 'tools/game-log/index.tsx',
        childRoutes: [
          {
            id: 'tools-game-log-detail',
            path: `${ROOT}tools/gameLog/detail`,
            label: '游戏回放详情',
            tsxPath: 'tools/game-log/detail/index.tsx',
          },
          {
            id: 'tools-game-log-detail-old',
            path: `${ROOT}tools/gameLog/detail-old`,
            label: '游戏回放详情旧版',
            tsxPath: 'tools/game-log/detail-old/index.tsx',
          },
        ],
      },
    ],
  },
  {
    id: 'admin',
    label: '后台管理',
    disabledRuleIdSet: [rule.admin, rule.operations, rule.customerService],
    childRoutes: [
      {
        id: 'admin-user',
        path: `${ROOT}admin/user`,
        label: '用户管理',
        tsxPath: 'admin/user/index.tsx',
      },
    ],
  },
];

export default class RouteWithRole {
  protected static routes: IRoute[];
  protected static routesMap: { [x: string]: IRoute };
  protected static routesMapValues: IRoute[];

  public readonly user: User;

  constructor(user: User) {
    this.user = user;
  }

  //将路由进行拆分，将符合权限的路由保留，其他的过滤
  protected filterRoutes(routes: IRoute[]): IRoute[] {
    return routes.filter((route) => {
      if (!this.user.ruleId) {
        //如果没有角色，则没有任何权限
        return false;
      }
      if (
        route.disabledRuleIdSet &&
        lodash.includes(route.disabledRuleIdSet, this.user.ruleId)
      ) {
        return false;
      }
      if (route.childRoutes) {
        route.childRoutes = this.filterRoutes(route.childRoutes);
        return !!route.childRoutes.length;
      }
      return true;
    });
  }

  //对路由进行拆分，变成一维的map结构
  protected flattenRoutes(routes: IRoute[], parentId?: string): IRoute[] {
    let flattenedRoutes: IRoute[] = [];

    for (const route of routes) {
      const clonedRoute: IRoute = JSON.parse(JSON.stringify(route));

      if (parentId) {
        clonedRoute.parentId = parentId;
      }

      flattenedRoutes.push(clonedRoute);

      if (clonedRoute.childRoutes) {
        const childRoutes = this.flattenRoutes(
          clonedRoute.childRoutes,
          clonedRoute.id,
        );
        flattenedRoutes = flattenedRoutes.concat(childRoutes);
      }
    }
    return flattenedRoutes;
  }

  //以多维的结构返回当前用户所拥有的路由
  routes(): IRoute[] {
    if (!RouteWithRole.routes) {
      RouteWithRole.routes = this.filterRoutes(ORIGINAL_ROUTES);
    }
    return RouteWithRole.routes;
  }

  //将routes转成map结构
  routesMap(): { [x: string]: IRoute } {
    if (!RouteWithRole.routesMap) {
      RouteWithRole.routesMap = {};
      const tmp = this.flattenRoutes(this.routes());
      tmp.map((route) => {
        delete route.childRoutes;
        RouteWithRole.routesMap[route.id] = route;
      });
    }
    return RouteWithRole.routesMap;
  }

  routesMapValues(): IRoute[] {
    if (!RouteWithRole.routesMapValues) {
      RouteWithRole.routesMapValues = Object.values(this.routesMap());
    }
    return RouteWithRole.routesMapValues;
  }

  url(
    routeId: string,
    params: { [key: string]: string | number } = {},
  ): string {
    let path = this.routesMap()[routeId].path;
    if (path === undefined) {
      return '';
    }
    Object.keys(params).map((name) => {
      path = path ? path.replace(`:${name}`, params[name].toString()) : '';
    });
    return path;
  }
}
